import React from 'react';

import { Layout } from '../components/layouts';
import { SEO } from '../components/common';
import {
  PillowProductSection,
  PillowAccordionSection,
  PillowLayerSection,
  PillowBenefitSection,
  MeetOurHappySleepersSection,
  WoosaPromiseIconGridSection,
  CompleteYourNightsSection,
  PillowLateralSection,
  PillowReviewGridSection,
  PillowProductFixedBottomBar,
} from '../components/sections';

const WoosaOriginalPillowPage: React.FC = () => (
  <Layout>
    <SEO
      title="The Woosa Pillow: Back and Side Sleeper Pillow"
      description="Experience plush support and cooling comfort with The Woosa Pillow made with Woosa's revolutionary Cloud Foam. The perfect back and side sleeper pillow."
      meta={[
        {
          name: 'keywords',
          content:
            'side sleeper pillow, back and side sleeper pillow, back and side sleeper ergonomic pillow singapore, buy side sleeper pillow online singapore',
        },
      ]}
    />
    <PillowProductSection />
    <PillowAccordionSection />
    <PillowLayerSection />
    <PillowBenefitSection />
    <MeetOurHappySleepersSection />
    <WoosaPromiseIconGridSection />
    <CompleteYourNightsSection type="Pillow" />
    <PillowReviewGridSection />
    <PillowLateralSection />
    <PillowProductFixedBottomBar />
  </Layout>
);

export default WoosaOriginalPillowPage;
